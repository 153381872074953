import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import lijnImage from "../images/lijn.png"
import instagramWhite from "../images/instagram.svg"
import linkedIn from "../images/linked-in.svg"

const LienPage = () => (
  <Layout useWhiteHeader useBlueBackground useHeaderBackground={false}>
    <SEO title="Lien" />
    <section className="section container ">
      <img src={lijnImage} alt="Foto Lien" />
      <div className="container__content">
        <h2>Lien</h2>
        <small className="sub-title">of lijn. Zoals je zelf wil.</small>
        <p>
          Dertiger • woont in Gent • freelance digitaal strateeg •
          mede-oprichter van nucompany • helpt bedrijven bij het ontwerpen van
          digitale ervaringen die er toe doen • nu ook podcaster • een meter
          zevenvijftig aan enthousiasme (met een hoek af) • spreekt graag voor
          publiek • droomt • leeft • probeert • houdt van een uitdaging • wordt
          blij van lachen, spelen, fleetwood mac, rosé en heerlijke babbels •
          wordt gek zonder sport, van loopjes tot crossfit en terug • beste
          vriend van konijn Emiel • verlangt steeds naar verbinding • leert
          houden van zichzelf • is onderweg.
        </p>
        <div className="container__content__socials">
          <a
            href="https://www.instagram.com/lienvdvelde"
            target="__blank"
            rel="noopener noreferrer"
          >
            <img src={instagramWhite} alt="Instagram logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/lienvandervelde"
            target="__blank"
            rel="noopener noreferrer"
          >
            <img src={linkedIn} alt="Linked in logo" />
          </a>
        </div>
      </div>
    </section>
  </Layout>
)

export default LienPage
